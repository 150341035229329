<template>
  <a-table :columns="columns" :data-source="dataSource" :loading="loading" :pagination="false" row-key="id">
    <template slot="ratio" slot-scope="ratio">{{ `${(ratio * 100).toFixed(2)}%` }}</template>
    <template slot="progress" slot-scope="text, record">
      <a-popover>
        <template slot="content">
          <div>已发现 URL: {{ record.urls_found }}</div>
          <div>已扫描 URL: {{ record.urls_scanned }}</div>
          <div>已发送请求: {{ record.requests_sent }}</div>
        </template>
        <a-progress
          :percent="percent(record.urls_scanned, record.urls_found, record.state)"
          size="small"
          :status="status(record.state)"
        ></a-progress>
      </a-popover>
    </template>
  </a-table>
</template>

<script>
import { getSiteScanTasks } from '@/api/site'

export default {
  name: 'SiteScanTaskTable',
  props: {
    siteId: { type: String, required: true }
  },
  data () {
    return {
      columns: [
        { dataIndex: 'created_at', title: '创建时间', width: 200 },
        { dataIndex: 'requests_failed_ratio', title: '请求失败率', width: 160, align: 'center', scopedSlots: { customRender: 'ratio' } },
        { dataIndex: 'response_time_average', title: '平均响应时间(ms)', width: 160, align: 'center' },
        { title: '进度', width: 200, scopedSlots: { customRender: 'progress' } }
      ],
      dataSource: [],
      loading: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getSiteScanTasks(this.siteId).then(res => { this.dataSource = res.data }).finally(() => { this.loading = false })
    },
    percent (scanned, found, state) {
      if (found) {
        const value = parseFloat((scanned / found * 100).toFixed(2))
        if (state === 'running' && value === 100.00) {
          return 99.00
        } else {
          return value
        }
      } else {
        return 0
      }
    },
    status (state) {
      switch (state) {
        case 'running': return 'active'
        case 'failed': return 'exception'
        case 'success': return 'success'
        default: return 'normal'
      }
    }
  }
}
</script>

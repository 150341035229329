<template>
  <a-tag :color="color">{{ name }}</a-tag>
</template>

<script>
export default {
  name: 'SeverityTag',
  props: {
    severity: { type: String, required: true }
  },
  data () {
    return { color: '', name: '' }
  },
  mounted () {
    switch (this.severity) {
      case 'high':
        this.color = 'red'
        this.name = '高危'
        break
      case 'medium':
        this.color = 'yellow'
        this.name = '中危'
        break
      case 'low':
        this.color = 'blue'
        this.name = '低危'
        break
      default:
        this.color = ''
        this.name = '未知'
        break
    }
  }
}
</script>

<template>
  <a-drawer title="解析 IP 详情" :visible="visible" :width="480" @close="() => { this.visible = false }">
    <a-descriptions :column="1" :title="detail.address">
      <a-descriptions-item label="国家">{{ detail.country }}</a-descriptions-item>
      <a-descriptions-item label="城市">{{ detail.city }}</a-descriptions-item>
      <a-descriptions-item label="组织">{{ detail.organization }}</a-descriptions-item>
      <a-descriptions-item label="ISP">{{ detail.isp }}</a-descriptions-item>
      <a-descriptions-item label="ASN">{{ detail.asn }}</a-descriptions-item>
    </a-descriptions>
    <a-divider></a-divider>
    <a-table :columns="columns" :data-source="detail.ports" :pagination="false" row-key="id">
      <template slot="port" slot-scope="text, record">
        <a-badge :status="record.state === 'open' ? 'success' : 'error'"></a-badge>
        <span>{{ record.port }} / {{ record.protocol }}</span>
      </template>
      <template slot="service" slot-scope="text, record">
        <a-tag color="blue">{{ record.service }}</a-tag>
        <span>{{ record.version }}</span>
      </template>
    </a-table>
  </a-drawer>
</template>

<script>
import { getIpAddress } from '@/api/ipAddress'

export default {
  name: 'IpAddressDrawer',
  props: {
    ipAddressId: { type: String, required: true }
  },
  data () {
    return {
      visible: false,
      detail: {},
      columns: [
        { title: '端口', width: 120, scopedSlots: { customRender: 'port' } },
        { dataIndex: 'service', title: '服务', width: 240, scopedSlots: { customRender: 'service' } }
      ]
    }
  },
  methods: {
    show () {
      getIpAddress(this.ipAddressId).then(res => { this.detail = res })
      this.visible = true
    }
  }
}
</script>

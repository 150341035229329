<template>
  <div>
    <a-table :columns="columns" :data-source="dataSource" :loading="loading" :pagination="false" row-key="id">
      <template slot="rule" slot-scope="rule">
        <severity-tag :severity="rule.severity"></severity-tag>
        <span>{{ rule.name }}</span>
      </template>
      <template slot="method" slot-scope="method">
        <a-tag color="blue">{{ method }}</a-tag>
      </template>
      <template slot="status" slot-scope="status">
        <a-tag :color="status < 400 ? 'green': 'red'">{{ status }}</a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button
          size="small"
          type="link"
          @click="$refs.siteRiskDetail.show(record)"
        >
          详情
        </a-button>
      </template>
    </a-table>
    <site-risk-drawer ref="siteRiskDetail"></site-risk-drawer>
  </div>
</template>

<script>
import { getSiteRisks } from '@/api/site'
import SeverityTag from '@/components/SeverityTag'
import SiteRiskDrawer from './SiteRiskDrawer'

export default {
  name: 'SiteRiskTable',
  components: { SeverityTag, SiteRiskDrawer },
  props: {
    siteId: { type: String, required: true }
  },
  data () {
    return {
      columns: [
        { dataIndex: 'site_risk_rule', title: '检测规则', width: 240, fixed: 'left', scopedSlots: { customRender: 'rule' } },
        { dataIndex: 'request_method', title: '请求方式', width: 240, align: 'center', scopedSlots: { customRender: 'method' } },
        { dataIndex: 'request_uri', title: 'URI', width: 320, ellipsis: true },
        { dataIndex: 'status_code', title: '状态码', width: 240, scopedSlots: { customRender: 'status' } },
        { title: '操作', width: 160, fixed: 'right', align: 'center', scopedSlots: { customRender: 'operation' } }
      ],
      dataSource: [],
      loading: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getSiteRisks(this.siteId).then(res => { this.dataSource = res.data }).finally(() => { this.loading = false })
    }
  }
}
</script>

import request from '@/utils/request'

export function getSites (params) {
  return request.get('/sites', { params })
}

export function createSite (data) {
  return request.post('/sites', data)
}

export function getSite (id) {
  return request.get(`/sites/${id}`)
}

export function deleteSite (id) {
  return request.delete(`/sites/${id}`)
}

export function verifySite (id) {
  return request.get(`/sites/${id}/verify`)
}

export function scanSite (id) {
  return request.get(`/sites/${id}/scan`)
}

export function getSiteRisks (id) {
  return request.get(`/sites/${id}/risks`)
}

export function getSiteScanTasks (id) {
  return request.get(`/sites/${id}/scan-tasks`)
}

export function getDomains (params) {
  return request.get('/domains', { params })
}

export function deleteDomain (id) {
  return request.delete(`/domains/${id}`)
}

<template>
  <a-drawer title="风险详情" :visible="visible" :width="480" @close="() => { this.visible = false }">
    <a-descriptions :column="1" :title="risk.request_uri">
      <a-descriptions-item label="请求方式">{{ risk.request_method }}</a-descriptions-item>
      <a-descriptions-item label="请求参数">{{ risk.request_params || '无' }}</a-descriptions-item>
      <a-descriptions-item label="请求数据">{{ risk.request_data || '无' }}</a-descriptions-item>
    </a-descriptions>
    <a-divider></a-divider>
    <div class="ant-descriptions-title">请求响应</div>
    <div v-html="risk.response"></div>
  </a-drawer>
</template>

<script>
export default {
  name: 'SiteRiskDrawer',
  data () {
    return {
      visible: false,
      risk: {}
    }
  },
  methods: {
    show (risk) {
      this.risk = risk
      this.visible = true
    }
  }
}
</script>

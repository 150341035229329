<template>
  <a-drawer title="SSL 证书详情" :visible="visible" :width="480" @close="visible = false">
    <a-descriptions :column="1">
      <a-descriptions-item label="序列号">{{ detail.serial_number }}</a-descriptions-item>
      <a-descriptions-item label="域名">{{ detail.subject }}</a-descriptions-item>
      <a-descriptions-item label="版本">{{ detail.version }}</a-descriptions-item>
      <a-descriptions-item label="签名算法">{{ detail.signature_algorithm }}</a-descriptions-item>
      <a-descriptions-item label="颁发者">{{ detail.issuer }}</a-descriptions-item>
      <a-descriptions-item label="颁发时间">{{ detail.not_before }}</a-descriptions-item>
      <a-descriptions-item label="到期时间">{{ detail.not_after }}</a-descriptions-item>
    </a-descriptions>
  </a-drawer>
</template>

<script>
import { getSslCertificate } from '@/api/sslCertificate'

export default {
  name: 'SslCertificateDrawer',
  props: {
    sslCertificateId: { type: String, required: true }
  },
  data () {
    return {
      visible: false,
      detail: {}
    }
  },
  methods: {
    show () {
      getSslCertificate(this.sslCertificateId).then(res => { this.detail = res })
      this.visible = true
    }
  }
}
</script>

<template>
  <div>
    <a-card :bordered="false" title="基本信息" style="margin-bottom: 24px;">
      <a-descriptions :title="detail.url">
        <a-descriptions-item label="解析 IP">
          <template v-if="detail.ip_address">
            <a @click="$refs.ipAddressDrawer.show()">{{ detail.ip_address.address }}</a>
            <ip-address-drawer ref="ipAddressDrawer" :ip-address-id="detail.ip_address.id"></ip-address-drawer>
          </template>
          <template v-else>未知</template>
        </a-descriptions-item>
        <a-descriptions-item v-if="detail.ssl_certificate" label="SSL 证书">
          <a @click="$refs.sslCertificateDrawer.show()">{{ detail.ssl_certificate.subject }}</a>
          <ssl-certificate-drawer ref="sslCertificateDrawer" :ssl-certificate-id="detail.ssl_certificate.id"></ssl-certificate-drawer>
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card :active-tab-key="key" :bordered="false" :tab-list="tabList" @tabChange="k => { this.key = k }">
      <a-space style="margin-bottom: 24px;">
        <a-button icon="reload" @click="handleReload"></a-button>
        <a-button type="primary" :disabled="disabled" @click="handleScan">{{ detail.state === 1 ? '扫描中' : '扫描' }}</a-button>
      </a-space>
      <template v-if="key === 'task'">
        <site-scan-task-table ref="taskTable" :site-id="siteId"></site-scan-task-table>
      </template>
      <template v-else>
        <site-risk-table ref="riskTable" :site-id="siteId"></site-risk-table>
      </template>
      <a-back-top></a-back-top>
    </a-card>
  </div>
</template>

<script>
import { getSite, scanSite } from '@/api/site'
import IpAddressDrawer from './modules/IpAddressDrawer'
import SiteRiskTable from './modules/SiteRiskTable'
import SiteScanTaskTable from './modules/SiteScanTaskTable'
import SslCertificateDrawer from './modules/SslCertificateDrawer'

export default {
  name: 'SiteDetail',
  components: {
    IpAddressDrawer,
    SiteRiskTable,
    SiteScanTaskTable,
    SslCertificateDrawer
  },
  data () {
    return {
      siteId: this.$route.params.id,
      detail: {},
      key: 'risk',
      tabList: [
        { key: 'risk', tab: '风险' },
        { key: 'task', tab: '扫描任务' }
      ],
      disabled: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getSite(this.siteId).then(res => { this.detail = res })
    },
    handleReload () {
      this.fetch()
      if (this.key === 'task') {
        this.$refs.taskTable.fetch()
      } else {
        this.$refs.riskTable.fetch()
      }
    },
    handleScan () {
      scanSite(this.siteId).then(res => { this.fetch() })
    }
  },
  watch: {
    detail: {
      handler (val) {
        this.disabled = val.state === 1
      },
      deep: true
    }
  }
}
</script>
